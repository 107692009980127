@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Light.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Medium.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BriosoPro';
  src: url('./assets/fonts/BriosoPro-Italic.otf');
  font-display: swap;
}

* {
  font-family: system-ui, sans-serif;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html, body {
  height: 100%;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}

svg .weightGraphicPath {
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation: weightGraphicDashAnimation 2s both;
}

@keyframes weightGraphicDashAnimation {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes opacityAnimation {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

/* @description: Hack for Paywall - for "OR" text */
div[class^="divider"] {
  font-family: 'SF Pro Text', serif;
}
